<template>
  <div class="relative nav-container">
    <div id="nav" class="nav-bar flex-center">
      <div>
        <img
          src="@/assets/image/sthlogo.png"
          style="width: 13rem"
          alt="samitivej image"
        />
      </div>
      <div class="ml-auto flex-center">
        <img
          v-if="lang == 'en'"
          src="@/assets/image/eng-lang.png"
          style="width: 3.4rem"
          alt="eng lang"
        />
        <img
          v-else
          src="@/assets/image/thai-lang.png"
          alt="thai lang"
          style="width: 3.4rem"
        />
        <el-select
          v-model="lang"
          class="m-2 ml-12"
          placeholder="Select"
          style="width: 15.8rem"
        >
          <el-option key="en" :label="$t('english')" value="en"> </el-option>
          <el-option key="th" :label="$t('thai')" value="th"> </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue3-i18n";

export default defineComponent({
  name: "Navbar",
  setup() {
    const i18n = useI18n();
    const setLocale = (lang) => {
      i18n.setLocale(lang);
    };

    return {
      setLocale,
    };
  },
  data() {
    return {
      lang: "th",
    };
  },
  mounted() {
    this.lang = !this.$route.query.lang ? this.lang : this.$route.query.lang;
  },
  watch: {
    lang(value) {
      this.setLocale(value);
      this.$emit("changeLang", value);
    },
  },

  methods: {},
});
</script>

<style lang="scss" scoped>
.nav-container {
  height: 0px;
}
.nav-bar {
  display: fixed;
  padding: 2.3rem 1rem 0;
  @media only screen and (min-width: 500px) {
    padding: 2.3rem 2.6rem 0;
  }
}
.el-input__inner {
  background-color: #f3f3e7;
}
.el-select .el-input__inner {
  background-color: #f3f3e7;
  cursor: pointer;
  display: inline-flex;
}
</style>
