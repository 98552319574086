<template>
  <div
    class="register-page normal"
    style="max-width: 400px; margin: auto; padding: 20px"
  >
    <div class="flex-mid pt-40 none-only-1100">
      <img
        src="@/assets/image/logo.png"
        class="mx-auto"
        style="width: 13rem"
        alt="samitivej image"
      />
    </div>
    <div class="mt-40">
      <div class="flex-center">
        <div style="width: 100%">
          <div class="mb-16 DBAdmanX" style="font-size: 2rem">
            {{ $t("password") }}
          </div>
          <el-input
            class="mb-16"
            type="password"
            v-model="password"
            style="width: 100%"
            inputmode="password"
          ></el-input>
        </div>
      </div>

      <div class="flex-center">
        <div style="width: 100%">
          <div class="mb-16 DBAdmanX" style="font-size: 2rem">
            {{ $t("confirmpass") }}
          </div>
          <el-input
            v-model="confirmPassword"
            type="password"
            style="width: 100%"
            inputmode="password"
          ></el-input>
        </div>
      </div>

      <div
        class="DBAdmanX"
        style="margin-top: 2rem; color: #999; font-size: 1.5rem"
      >
        <p>{{ $t("PassSuggest") }}</p>
      </div>

      <p class="errmessage">{{ this.errmsg }}</p>
    </div>
    <button
      style="width: 16.2rem; margin-top: 50px"
      class="submit-button mx-auto"
      @click="onSubmit"
    >
      submit
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import * as config from "@/assets/js/config.js";
var AmazonCognitoIdentity = require("amazon-cognito-identity-js");

export default defineComponent({
  name: "ChangePass",
  data() {
    return {
      callback: false,
      showerr: false,
      errcode: "",
      errmsg: "",
      username: "",
      valid: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        // eslint-disable-next-line
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      password: "",
      confirmPassword: "",
      passRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Password must be at least 8 characters",
      ],
      hidepw: true,
      loader: false,
      loading: false,
      lang: "th",
    };
  },
  mounted() {
    this.lang = !this.$route.query.lang ? this.lang : this.$route.query.lang;
    let param = this.$route.href.substring(8);
    if (param.length > 10) {
      let paramDecode = atob(param);
      let params = paramDecode.split("&lang=");
      this.email = params[0].substring(9);
      this.lang = params[1];
    }
  },
  methods: {
    onChangeLang(value) {
      this.lang = value;
    },
    onSubmit() {
      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];
      var authenticationData = {
        Username: this.email,
        Password: this.password,
      };
      var authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

      this.$store.state.userPool = new AmazonCognitoIdentity.CognitoUserPool(
        config.poolData
      );

      var userData = {
        Username: this.email,
        Pool: this.$store.state.userPool,
      };
      this.$store.state.cognitoUser = new AmazonCognitoIdentity.CognitoUser(
        userData
      );
      this.$store.state.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          if (!this.callback) {
            this.callback = true;
            this.$store.state.authenticated = true;
            this.$store.state.username = this.email;
            this.username = this.email;
            this[l] = false;
            this.loader = null;

            const cognitoUser = this.$store.state.userPool.getCurrentUser();
            if (cognitoUser != null) {
              cognitoUser.getSession((err, session) => {
                if (err) {
                  // console.log(err);
                } else if (!session.isValid()) {
                  // console.log("Invalid session.");
                } else {
                  // console.log("IdToken: " + session.getIdToken().getJwtToken());
                  window.localStorage.setItem(
                    "access_token",
                    session.getIdToken().getJwtToken()
                  );
                }
              });
            } else {
              // console.log("User not found.");
            }
            this.$router.push({ path: "/booking", query: { lang: this.lang } });
          }
        },
        onFailure: (err) => {
          // console.log(err);
          this.errmsg = err;
          if (!this.callback) {
            // console.log("sign in failure");

            this.errcode = JSON.stringify(err.code);
            this[l] = false;
            this.loader = null;
          }
        },
      });
    },
    navRreset: function () {
      this.$router.push({ path: "/forgot", query: { lang: this.lang } });
    },
    getMessage: function () {
      return this.message;
    },
  },
});
</script>

<style lang="scss" scoped>
.register-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.home-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 899px auto;

  @media only screen and (max-width: 899px) {
    grid-template-columns: 100vw 0;
  }
}
.welcom-box {
  width: calc(100vw - 3.2rem);
  max-width: 57.5rem;
}
.errmessage {
  color: red;
  margin-top: 5px;
}

.image {
  width: 100%;
  height: 100vh;
  background: gray;
  object-fit: cover;
}
</style>
