<template>
  <navbar @changeLang="onChangeLang"></navbar>
  <div
    v-if="!codesent"
    class="register-page normal"
    style="max-width: 400px; margin: auto; padding: 20px; margin-top: 30px"
  >
    <div class="flex-mid pt-40 none-only-1100">
      <img
        src="@/assets/image/logo.png"
        class="mx-auto"
        style="width: 13rem"
        alt="samitivej image"
      />
    </div>
    <div class="mt-40">
      <div class="flex-center">
        <div style="width: 100%">
          <div class="label mb-16">{{ $t("email") }}</div>
          <el-input
            class="mb-16"
            v-model="email"
            style="width: 100%"
            inputmode="email"
          ></el-input>
        </div>
      </div>
      <p class="errmessage">{{ this.errmsg }}</p>
    </div>
    <button
      style="width: 16.2rem; margin-top: 50px"
      class="label submit-button mx-auto"
      @click="onFind"
      :disabled="loading"
    >
      <div v-if="loading">
        <i class="fa fa-spinner fa-spin" style="margin-right: 10px"></i>
        {{ $t("loading") }}
      </div>
      <div v-else>{{ $t("send") }}</div>
    </button>
  </div>

  <div
    v-else
    class="register-page normal"
    style="max-width: 400px; margin: auto; padding: 20px; margin-top: 30px"
  >
    <div class="flex-mid pt-40 none-only-1100">
      <img
        src="@/assets/image/logo.png"
        class="mx-auto"
        style="width: 13rem"
        alt="samitivej image"
      />
    </div>
    <div class="mt-40">
      <div class="flex-center">
        <div style="width: 100%">
          <div class="label mb-16">{{ $t("code") }}</div>
          <el-input
            class="mb-16"
            v-model="code"
            style="width: 100%"
            inputmode="text"
          ></el-input>
        </div>
      </div>
      <div class="flex-center">
        <div style="width: 100%">
          <div class="label mb-16">{{ $t("newPassword") }}</div>
          <el-input
            class="mb-16"
            v-model="password"
            style="width: 100%"
            type="password"
            inputmode="password"
          ></el-input>
        </div>
      </div>
      <p class="errmessage">{{ this.errmsg }}</p>
    </div>
    <button
      style="width: 16.2rem; margin-top: 50px"
      class="label submit-button mx-auto"
      @click="onSubmit"
      :disabled="loading"
    >
      <div v-if="loading">
        <i class="fa fa-spinner fa-spin" style="margin-right: 10px"></i>
        {{ $t("loading") }}
      </div>
      <div v-else>{{ $t("send") }}</div>
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import * as config from "@/assets/js/config.js";
var AmazonCognitoIdentity = require("amazon-cognito-identity-js");
import Navbar from "@/components/NavbarSignin.vue";
var userPool = [];
export default defineComponent({
  name: "Forgot",
  components: {
    Navbar,
  },
  data() {
    return {
      codesent: false,
      callback: false,
      showerr: false,
      errcode: "",
      errmsg: "",
      username: "",
      valid: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        // eslint-disable-next-line
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      password: "",
      passRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Password must be at least 8 characters",
      ],
      code: "",
      codeRules: [(v) => !!v || "Code is required"],
      hidepw: true,
      loader: false,
      loading: false,
      lang: "th",
    };
  },
  mounted() {
    this.lang = !this.$route.query.lang ? this.lang : this.$route.query.lang;
  },

  watch: {
    errcode() {
      // console.log('watched error code: ' + this.errcode)
      if (this.errcode !== "") {
        if (this.errcode === '"CodeMismatchException"') {
          this.errmsg = this.$t("codeMismatchException");
        } else if (this.errcode === '"NotAuthorizedException"') {
          this.errmsg = this.$t("notAuthorizedException");
        } else if (this.errcode === '"UserNotFoundException"') {
          this.errmsg = this.$t("userNotFoundException");
        } else if (this.errcode === '"LimitExceededException"') {
          this.errmsg = this.$t("limitExceededException");
        } else if (this.errcode === '"UserNotConfirmedException"') {
          this.errmsg = this.$t("userNotConfirmedException");
        }
        this.showerr = true;
      }
    },
  },
  methods: {
    onChangeLang(value) {
      this.lang = value;
      window.localStorage.setItem("lang", value);
    },
    onFind() {
      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];

      userPool = new AmazonCognitoIdentity.CognitoUserPool(config.poolData);
      var userData = {
        Username: this.email,
        Pool: userPool,
      };
      // console.log('password forgot for ' + userData.Username)
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      this.showerr = false;
      this.errcode = "";

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          // successfully initiated reset password request
          // console.log('forgot password initiated: ' + JSON.stringify(data))
          this[l] = false;
          this.loader = null;
        },
        onFailure: (err) => {
          this.errmsg = err.message;
          var code = JSON.stringify(err.code);
          // console.log('forgot password error: ' + code)
          this.errcode = code;
          this[l] = false;
          this.loader = null;
        },
        // Optional automatic callback
        inputVerificationCode: (data) => {
          var result = JSON.stringify(data);
          // console.log('Code sent to: ' + result)
          this.codesent = true;
          this[l] = false;
          this.loader = null;
        },
      });
    },
    onSubmit() {
      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];

      userPool = new AmazonCognitoIdentity.CognitoUserPool(config.poolData);
      var userData = {
        Username: this.email,
        Pool: userPool,
      };
      // console.log('password change for ' + userData.Username)
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      this.showerr = false;
      this.errcode = "";

      cognitoUser.confirmPassword(this.code, this.password, {
        onSuccess: (data) => {
          // successfully initiated reset password request
          // console.log('forgot password confirmed: ' + JSON.stringify(data))
          this[l] = false;
          this.loader = null;
          this.$router.push({ path: "/signin", query: { lang: this.lang } });
        },
        onFailure: (err) => {
          this.errmsg = err.message;
          var code = JSON.stringify(err.code);
          // console.log('forgot password confirm error: ' + code)
          this.errcode = code;
          this[l] = false;
          this.loader = null;
        },
      });
    },

    navRreset: function () {
      this.$router.push({ path: "/forgot", query: { lang: this.lang } });
    },
    getMessage: function () {
      return this.message;
    },
  },
});
</script>

<style lang="scss" scoped>
.register-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.home-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 899px auto;

  @media only screen and (max-width: 899px) {
    grid-template-columns: 100vw 0;
  }
}
.welcom-box {
  width: calc(100vw - 3.2rem);
  max-width: 57.5rem;
}
.errmessage {
  color: red;
  margin-top: 5px;
}
.label {
  font-size: 16px;
  @media only screen and (max-width: 899px) {
    font-size: 14px;
  }
}
.label-send {
  font-size: 16px;
  @media only screen and (max-width: 899px) {
    font-size: 14px;
  }
}
.image {
  width: 100%;
  height: 100vh;
  background: gray;
  object-fit: cover;
}
</style>
