<template>
  <div>
    <svg
      width="13"
      height="22"
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4468 1.02266C13.0441 1.61432 13.0441 2.57359 12.4468 3.16525L4.35073 11.1842L12.4468 19.2031C13.0441 19.7948 13.0441 20.754 12.4468 21.3457C11.8494 21.9374 10.8809 21.9374 10.2836 21.3457L1.10595 12.2555C0.508597 11.6638 0.508597 10.7045 1.10595 10.1129L10.2836 1.02266C10.8809 0.431004 11.8494 0.431004 12.4468 1.02266Z"
        fill="#037968"
      />
    </svg>
  </div>
</template>
