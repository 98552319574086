<template>
  <NavbarSignin :lang="lang" @changeLang="onChangeLang"></NavbarSignin>
  <div
    v-if="page == 'signin'"
    class="register-page normal"
    style="max-width: 400px; margin: auto; padding: 20px; margin-top: 30px"
  >
    <form>
      <div class="flex-mid pt-40 none-only-1100">
        <img
          src="@/assets/image/logo.png"
          class="mx-auto"
          style="width: 13rem"
          alt="samitivej image"
        />
      </div>
      <div class="mt-40">
        <div class="flex-center">
          <div style="width: 100%">
            <div class="label mb-16">{{ $t("email") }}</div>
            <el-input
              class="normal2 mb-16"
              v-model="email"
              style="width: 100%"
              inputmode="email"
            ></el-input>
          </div>
        </div>
        <!-- <p style="color:red;">{{ validEmail(this.email) }}</p> -->
        <div class="flex-center">
          <div style="width: 100%">
            <div class="label mb-16">{{ $t("password") }}</div>
            <el-input
              v-model="password"
              class="normal2"
              type="password"
              style="width: 100%"
              inputmode="password"
            ></el-input>
          </div>
        </div>
        <!-- <p style="color:red;">{{ validPassword(this.password) }}</p> -->
        <p class="errmessage">{{ this.errmsg }}</p>
        <div style="display: flex; justify-content: space-between">
          <h4
            class="forgot left"
            @click="$router.push({ path: '/', query: { lang: lang } })"
          >
            {{ $t("register") }}
          </h4>
          <h4
            class="forgot right"
            @click="$router.push({ path: '/forgot', query: { lang: lang } })"
          >
            {{ $t("forgot") }}
          </h4>
        </div>
      </div>
    </form>
    <button
      style="width: 16.2rem; margin-top: 50px"
      class="label submit-button mx-auto"
      @click="onSubmit"
      :disabled="loading"
    >
      <div v-if="loading">
        <i class="fa fa-spinner fa-spin" style="margin-right: 10px"></i>
        {{ $t("loading") }}
      </div>
      <div v-else>{{ $t("signin") }}</div>
    </button>
  </div>
  <div
    v-if="page == 'changepass'"
    class="register-page normal"
    style="max-width: 400px; margin: auto; padding: 20px; margin-top: 30px"
  >
    <form>
      <div class="flex-mid pt-40 none-only-1100">
        <img
          src="@/assets/image/logo.png"
          class="mx-auto"
          style="width: 13rem"
          alt="samitivej image"
        />
      </div>
      <div class="mt-40">
        <div class="flex-center">
          <div style="width: 100%">
            <div class="label mb-16">{{ $t("password") }}</div>
            <el-input
              class="mb-16"
              type="password"
              v-model="newPassword"
              style="width: 100%"
              inputmode="password"
            ></el-input>
          </div>
        </div>
        <div class="flex-center">
          <div style="width: 100%">
            <div class="label mb-16">{{ $t("confirmpass") }}</div>
            <el-input
              v-model="confirmPassword"
              type="password"
              style="width: 100%"
              inputmode="password"
            ></el-input>
          </div>
        </div>
        <p class="errmessage">{{ this.errmsg }}</p>
      </div>
    </form>
    <button
      style="width: 16.2rem; margin-top: 50px"
      class="label submit-button mx-auto"
      @click="handleNewPassword"
      :disabled="loading"
    >
      <div v-if="loading">
        <i class="fa fa-spinner fa-spin" style="margin-right: 10px"></i>
        {{ $t("loading") }}
      </div>
      <div v-else>{{ $t("submit") }}</div>
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import * as config from "@/assets/js/config.js";
var AmazonCognitoIdentity = require("amazon-cognito-identity-js");
import NavbarBooking from "@/components/NavbarBooking.vue";
import NavbarSignin from "@/components/NavbarSignin.vue";

export default defineComponent({
  name: "Signin",
  components: {
    // NavbarBooking,
    NavbarSignin,
  },
  data() {
    return {
      firstSubmit: true,
      sessionUserAttributes: null,
      page: "signin",
      callback: false,
      showerr: false,
      errcode: "",
      errmsg: "",
      username: "",
      email: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      valid: false,
      hidepw: true,
      loader: false,
      loading: false,
      lang: "th",
    };
  },
  mounted() {
    this.lang = !this.$route.query.lang ? this.lang : this.$route.query.lang;
    let param = this.$route.href.substring(8);
    if (param.length > 10) {
      let paramDecode = atob(param);
      let params = paramDecode.split("&lang=");
      this.email = params[0].substring(9);
      this.lang = params[1];
    }
  },
  watch: {
    errcode() {
      if (this.errcode !== "") {
        if (this.errcode === '"NotAuthorizedException"') {
          this.errmsg = this.$t("notAuthorizedException");
        } else if (this.errcode === '"UserNotFoundException"') {
          this.errmsg = this.$t("notAuthorizedException");
        } else if (this.errcode === '"UserNotConfirmedException"') {
          this.errmsg = this.$t("userNotConfirmedException");
        } else if (this.errcode === '"LimitExceededException"') {
          this.errmsg = this.$t("limitExceededException");
        }
        this.showerr = true;
      } else {
        this.showerr = false;
      }
    },
    newPassword() {
      this.errmsg = this.$t(this.validNewPassword(this.newPassword));
    },
  },
  methods: {
    onChangeLang(value) {
      this.lang = value;
      window.localStorage.setItem("lang", value);
    },
    onSubmit() {
      this.firstSubmit = false;
      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];
      var authenticationData = {
        Username: "web_" + this.email,
        Password: this.password,
      };
      var authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      this.$store.state.userPool = new AmazonCognitoIdentity.CognitoUserPool(
        config.poolData
      );
      var userData = {
        Username: "web_" + this.email,
        Pool: this.$store.state.userPool,
      };
      this.$store.state.cognitoUser = new AmazonCognitoIdentity.CognitoUser(
        userData
      );
      this.$store.state.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          if (!this.callback) {
            this.callback = true;
            this.$store.state.authenticated = true;
            this.$store.state.username = "web_" + this.email;
            this.username = "web_" + this.email;
            this[l] = false;
            this.loader = null;
            this.errmsg = "";
            const cognitoUser = this.$store.state.userPool.getCurrentUser();
            if (cognitoUser != null) {
              cognitoUser.getSession((err, session) => {
                if (err) {
                  console.log(err);
                } else if (!session.isValid()) {
                  // console.log("Invalid session.");
                } else {
                  window.localStorage.setItem(
                    "access_token",
                    session.getIdToken().getJwtToken()
                  );
                  window.localStorage.setItem("lang", this.lang);
                  this.$router.push({
                    path: "/booking",
                    query: { lang: this.lang },
                  });
                }
              });
            } else {
              // console.log("User not found.");
            }
            // this.$router.push("/booking");
          }
        },
        onFailure: (err) => {
          this.errmsg = err.message;
          if (!this.callback) {
            // console.log("sign in failure");
            this.errcode = JSON.stringify(err.code);
            this[l] = false;
            this.loader = null;
          }
          this[l] = false;
          this.loader = null;
        },
        newPasswordRequired: (userAttributes) => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.
          // console.log(userAttributes);
          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          delete userAttributes.phone_number_verified;
          delete userAttributes.phone_number;
          delete userAttributes.email;

          // store userAttributes on global variable
          this.sessionUserAttributes = userAttributes;
          this.page = "changepass";
          this.errmsg = "";
          this[l] = false;
          this.loader = null;
        },
      });
    },
    // validEmail(v) {
    //   if (this.firstSubmit) {
    //     return null;
    //   }if (!v) {
    //     return "E-mail is required";
    //   }
    //   else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) {
    //     return "E-mail must be valid";
    //   }else{
    //     return null;
    //   }
    // },
    // validPassword(v) {
    //   if (this.firstSubmit) {
    //     return null;
    //   }else if (!v) {
    //     return "Password is required";
    //   }
    //   return null;
    // },
    validNewPassword(v) {
      if (!v) {
        return "requiredPass";
      }
      if (v.length < 8 || v.length > 20) {
        return "more8TO20Pass";
      }
      if (!/^(?=.*[0-9])/.test(v)) {
        return "numberPass";
      }
      if (!/^(?=.*[a-z])/.test(v)) {
        return "lowerPass";
      }
      if (!/^(?=.*[A-Z])/.test(v)) {
        return "upperPass";
      }
      if (!/^(?=.*[!@#$%^&*"])/.test(v)) {
        return "SpecialCharPass";
      }
      return null;
    },
    handleNewPassword() {
      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];
      if (this.newPassword != this.confirmPassword) {
        this.errmsg = this.$t("dontMatchPass");
        this[l] = false;
        this.loader = null;
      } else {
        this.loader = "loading";
        const l = this.loader;
        this[l] = !this[l];
        this.$store.state.cognitoUser.completeNewPasswordChallenge(
          this.newPassword,
          this.sessionUserAttributes,
          {
            onSuccess: () => {
              if (!this.callback) {
                this.callback = true;
                // console.log("sign in success");
                this.$store.state.authenticated = true;
                this.$store.state.username = "web_" + this.email;
                this.username = "web_" + this.email;
                this[l] = false;
                this.loader = null;
                this.errmsg = "";

                const cognitoUser = this.$store.state.userPool.getCurrentUser();
                if (cognitoUser != null) {
                  cognitoUser.getSession((err, session) => {
                    if (err) {
                      // console.log(err);
                    } else if (!session.isValid()) {
                      // console.log("Invalid session.");
                    } else {
                      window.localStorage.setItem(
                        "access_token",
                        session.getIdToken().getJwtToken()
                      );
                      window.localStorage.setItem("lang", this.lang);
                      this.$router.push({
                        path: "/booking",
                        query: { lang: this.lang },
                      });
                    }
                  });
                } else {
                  // console.log("User not found.");
                }

                // this.$router.push("/booking");
              }
            },
            onFailure: (err) => {
              this.errmsg = err.message;
              if (!this.callback) {
                // console.log("sign in failure");
                this.errcode = JSON.stringify(err.code);
                this[l] = false;
                this.loader = null;
              }
            },
          }
        );
      }
    },
    navRreset: function () {
      this.$router.push({ path: "/forgot", query: { lang: this.lang } });
    },
    getMessage: function () {
      return this.message;
    },
  },
});
</script>

<style lang="scss" scoped>
.register-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.home-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 899px auto;

  @media only screen and (max-width: 899px) {
    grid-template-columns: 100vw 0;
  }
}
.welcom-box {
  width: calc(100vw - 3.2rem);
  max-width: 57.5rem;
}
.label {
  font-size: 2rem;
  @media only screen and (max-width: 899px) {
    font-size: 1.8rem;
  }
}
.errmessage {
  color: red;
  margin-top: 5px;
}
.forgot {
  font-size: 16px;
  color: black;
  margin-top: 10px;
  cursor: pointer;
  @media only screen and (max-width: 899px) {
    font-size: 14px;
  }
}
.forgot:hover {
  color: #206b5e;
}
.image {
  width: 100%;
  height: 100vh;
  background: gray;
  object-fit: cover;
}
</style>
